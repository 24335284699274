import React from 'react'

import Container from '../Container'

import './OurValues.css'

const OurValues = () => {
    return (
        <div>
            <Container home={false}> 
                <div className="values-home">
                    <div className="row-2" >
                        <div class="values-box">
                            <i class="fas fa-handshake values-box-icon u-margin-bottom-small"></i>
                            <div class="lab-heading u-margin-bottom-small text-medium">
                                Business
                            </div>
                            <div class="lab-paragraph text-small">
                                Deccan Tobacco being established as a major exporter recognizes the importance of being 
                                a responsible entity. Being cognizant of this fact, we strive to be active and committed in 
                                enhancing the capabilities of communities it works, lives and does business. <br/>Besides strong 
                                internal responsible business policies that govern all its actions, we have undertaken several 
                                initiatives i.e., various GAP (Good Agricultural Practices) measures; farmer support programs; 
                                environmental management.<br/> Deccan Tobacco has built an enduring reputation in India and 
                                abroad due to its commitment to quality and ecologically sound practices
                            </div>
                        </div>
                        <div class="values-box">
                            <i class="fas fa-user-tie values-box-icon u-margin-bottom-small"></i>
                            <div class="lab-heading u-margin-bottom-small text-medium">
                                Client Focus
                            </div>
                            <div class="lab-paragraph text-small">                    
                                Deccan Tobacco Company has the ability to process and pack tobacco as per customer 
                                requirements. We are committed to follow sound industrial, agricultural policies and maintain 
                                quality product.<br />
                            </div>
                        </div>
                        {/* <div class="values-box">
                            <i class="fas fa-leaf values-box-icon u-margin-bottom-small"></i>
                            <div class="lab-heading u-margin-bottom-small text-medium">
                                Farmers
                            </div>
                            <div class="lab-paragraph text-small">
                                Farmers are the backbone of India as well as our company too. And we acknowledge their value. We are associated with farmers from Purchoor, Warangal region.<br/>
                                We jointly work with farmers through field technicians, field managers to  not only educate about GPA (good agricultural practices)
                                in fields but also their human and labour rights which fall under international labour organization act.<br/>We continously communicate 
                                with our teams to address the issues related to basic rights such as Safe and sanitary working conditions , correct wages and working 
                                time of the farmers.<br/>
                            </div>
                        </div> */}
    
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default OurValues
