import React from 'react'

import Container from '../Container'

import './Factory.css'

import Factory1 from '../../assets/Warehouse.JPG'
import Factory2 from '../../assets/Plant.JPG'
import Factory3 from '../../assets/cartons.jpg'
import QualityControl from '../QualityControl/QualityControl'

const Factory = () => {
    return (
        <div>
            <Container home={false}>
                <section className="section-factory"> 
                    <div className="factory-home u-margin-bottom-small"> 
                         <div id="carouselExampleIndicators" className="carousel slide u-padding carousel-holder" data-bs-ride="carousel">
                                <div className="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div className="carousel-inner carousel-img-holder">
                                <div className="carousel-item carousel-img-item active">
                                    <img src={Factory1} className="d-block carousel-img w-100" alt="Loading..." />
                                </div>
                                <div className="carousel-item carousel-img-item">
                                    <img src={Factory2} className="d-block carousel-img w-100" alt="Loading..." />
                                </div>
                                <div className="carousel-item carousel-img-item">
                                    <img src={Factory3} className="d-block carousel-img w-100" alt="Loading..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div> 
                        <div className="foreground"> 
                            <div className="u-center u-margin-top-medium factory-header">
                                <p className="text-subheading-1">
                                    Factory
                                </p>
                            </div>
                            <div className="u-padding u-center factory-subheading-holder" >
                                <div className="force-center" style={{width: '80%'}}> 
                                    {/* <h3 className="factory-heading u-margin-bottom-small text-medium">
                                        Subheading-1
                                    </h3> */}
                                    <p className="factory-paragraph u-margin-bottom-small text-small">
                                    Our commitment for a
                                    vertically integrated un-manufactured tobacco supplier led to building a Threshing Factory
                                    at Pedakakani, Guntur. We not only purchase, process, store but also provide agronomy
                                    support to grow tobacco. 
                                    </p>
                                    <h3 className="factory-heading u-margin-bottom-small text-medium">
                                        Certifications
                                    </h3>
                                    <p className="factory-paragraph u-margin-bottom-small text-small">
                                        Our facility is furnished with the state of the art equipment and processes. Industry
                                        accreditation for Quality Management i.e., ISO 9001:2015 certification is further proof of the
                                        impeccable delivery systems and processes used at the facility
                                    </p>
                                    <p className="factory-paragraph u-margin-bottom-small text-small">
                                        Our commitment for Environmental Management is evidenced by the ISO 14001:2015
                                        certification, which has been awarded to our facility.
                                    </p>
                                    <p className="factory-paragraph u-margin-bottom-small text-small">
                                        The facility is also certified with ISO 45001:2018, which evidences its commitment towards
                                        Occupational Health and Safety Management system.
                                    </p>
                                    {/* <h3 className="factory-heading u-margin-bottom-small text-medium">
                                        Subheading-3
                                    </h3>
                                    <p className="factory-paragraph u-margin-bottom-small text-small">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <QualityControl />
                </section>
                

            </Container>
        </div>
    )
}

export default Factory
