import React,{useState} from 'react'
import { NavLink } from 'react-router-dom';

import headerLogo from '../../assets/logo1.png'

import Container from '../Container'
import './Header.css'

const Header = () => {

  return (
    <div className="header">
      <Container home={true}> 
        <div className="text-box">
            <img src={headerLogo} alt="Logo" className="image" />
            <h1 className="heading-primary">
                <span className="heading-primary-main text-large u-margin-bottom-small">Deccan Tobacco Company</span>
                <span className="heading-primary-sub text-medium">Leading exporter of un-manufactured tobacco from India</span>
            </h1>

            <NavLink to="/contact" className="btn btn-white">Contact Us</NavLink>
        </div>
      </Container>
    </div>
  )
}

export default Header

