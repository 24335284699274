import React,{useState,useEffect} from 'react'
import { NavLink } from 'react-router-dom';

import logo from '../assets/logo1.png'

import NavlinksHolder from './Navbar/NavlinksHolder'
import Navbar from './Navbar/Navbar'
import MininavHolder from './Mininav/MininavHolder'

const Container = props => {
    const [openBack,setOpenBack] = useState(false)
    const [isMobile, setIsMobile] = useState(window.innerWidth < 786);


    useEffect(() => {
        window.addEventListener("resize", () => {
            const ismobile = window.innerWidth < 786;
            if (ismobile !== isMobile) setIsMobile(ismobile);
        }, false);
    }, [isMobile]);

    const handleBackChange = () => {
        setOpenBack(!openBack)
    }
    // if(isMobile){
    //     return(
    //         <div>
    //             <MininavHolder handleBackChange={handleBackChange} back={openBack} />
    //             {props.home && <NavLink to="/" className="logo-box">
    //                 <img src={logo} alt="Logo" className="logo" />
    //             </NavLink>} 
                
    //             {!openBack && props.children}
    //         </div>  
    //     )
    // }
    return (
        <div>
           
            {(props.home) ? <NavlinksHolder isMobile={isMobile} home={true} />:<Navbar isMobile={isMobile} />}
            <MininavHolder handleBackChange={handleBackChange} back={openBack} />
            {props.home && <NavLink to="/" className="logo-box">
                <img src={logo} alt="Logo" className="logo" />
            </NavLink>} 
            
            {!openBack && props.children}
            
        </div>
    )
}

export default Container
