import React from 'react'
import { NavLink } from 'react-router-dom';

import './Mininav.css'

const Mininav = () => {
    return (
        <div className="small-nav">
            <nav className="small-nav-navbar">
                <ul className="small-nav-list">
                    <li className="small-nav-item">
                        <NavLink to="/factory" className="small-nav-link">Factory</NavLink>
                    </li>
                    <li className="small-nav-item">
                        <NavLink to="/ourvalues" className="small-nav-link">Our Values</NavLink>
                    </li>
                    {/* <li className="small-nav-item">
                        <NavLink to="/clients" className="small-nav-link text-medium">Client Focus</NavLink>
                    </li> */}
                    {/* <li className="small-nav-item">
                        <NavLink to="/sustainability" className="small-nav-link">Sustainability</NavLink>
                    </li>*/}
                    <li className="small-nav-item">
                        <NavLink to="/legacy" className="small-nav-link">Legacy</NavLink>
                    </li> 
                    <li className="small-nav-item">
                        <NavLink to="/contact" className="small-nav-link">Contact Us</NavLink>
                    </li> 
                </ul>
            </nav>
        </div>
    )
}

export default Mininav

