import React from 'react'
import { NavLink } from 'react-router-dom';

import './Navbar.css'

const NavlinksHolder = (props) => {
    if(props.isMobile){
        return(null)
    }
    return (
        <div className="nav-links-holder">
            <ul className={props.home?"nav-links":"non-home-nav-links"}>
                <li className="small-nav-item">
                    <NavLink to="/factory" className="small-nav-link text-medium">Factory</NavLink>
                </li>
                <li className="small-nav-item">
                    <NavLink to="/ourvalues" className="small-nav-link text-medium">Our Values</NavLink>
                </li>
                {/* <li className="small-nav-item">
                    <NavLink to="/clients" className="small-nav-link text-medium">Client Focus</NavLink>
                </li> */}
                {/* <li className="small-nav-item">
                    <NavLink to="/sustainability" className="small-nav-link text-medium">Sustainability</NavLink>
                </li>*/}
                <li className="small-nav-item">
                    <NavLink to="/legacy" className="small-nav-link text-medium">Legacy</NavLink>
                </li> 
            </ul>
        </div>
    )
}

export default NavlinksHolder

