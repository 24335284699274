import React from 'react'
import {BrowserRouter as Router,Route,Switch,Redirect} from 'react-router-dom';

import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'
import Factory from './components/Factory/Factory'
import OurValues from './components/OurValues/OurValues';
import Sustainability from './components/Sustainability/Sustainability';
import ContactUs from './components/ContactUs/ContactUs';

import './App.css'

const App = () => {
  return (
    <div className="app">
      <Router>
        <Switch>
          <Route path='/' exact>
            <Header /> 
          </Route>
          <Route path='/factory' exact>
              <Factory/>
          </Route>
          <Route path='/ourvalues' exact>
              <OurValues/>
          </Route>
          {/* <Route path='/sustainability' exact>
              <Sustainability/>
          </Route> */}
          <Route path='/contact' exact>
              <ContactUs/>
          </Route>
          <Redirect to='/' />
        </Switch>
        <Footer />
      </Router>
    </div>
    
  )
}

export default App

