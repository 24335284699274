import React from 'react'

import headerLogo from '../../assets/logo1.png'

import Container from '../Container'

import './ContactUs.css'

const ContactUs = () => {
    return (
        <div>
            <Container home={false}> 
                <div className="u-padding contact-home">
                    <div className="force-center contact-holder" >
                        <img src={headerLogo} alt="Logo" className="image" />
                        <p className="text-subheading-1 header-contact"> 
                            Our Contacts
                        </p>
                        <div className="info-holder">
                            <div className="info-address"> 
                                <p>OFFICE:</p>
                                <p>Deccan Tobacco Company</p>  
                                <p>5-7-6, 1/5 Brodiepet</p> 
                                <p>Guntur - 522 002, India</p> 
                            </div>
                            <div className="info-phone"> 
                                <p>Telephone No:</p>
                                <p>T: +91 863 2231184</p>  
                                <p>F: +91 863 2354647</p>
                            </div>
                            <div className="info-email"> 
                                <p>Email:</p>
                                <p>info@deccantobacco.com</p>
                            </div>
                        </div>
                    </div>
                </div>

            </Container>
        </div>
    )
}

export default ContactUs
