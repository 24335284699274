import React from 'react'

import Lab1 from '../../assets/Lab3.png'
import Lab2 from '../../assets/Lab1.png'
import Lab3 from '../../assets/Lab2.png'
import Inside1 from '../../assets/Inside1.JPG'
import Inside2 from '../../assets/Inside2.JPG'

import './QualityControl.css'

const QualityControl = () => {
    return (
        <div className="u-padding u-margin-top-medium">
            <div class="u-center u-margin-bottom-large u-margin-top-medium">
                <p class="text-subheading-2">
                    Quality Control
                </p>
            </div>

            <div className="row-1-2 u-padding qc-holder">
                <div style={{position: 'relative'}} > 
                    {/* <div className="u-padding u-center col-matter"> */}
                        {/* <p className="lab-heading u-margin-bottom-small text-small">
                            Quality
                        </p> */}
                        {/* <p className="lab-paragraph u-margin-bottom-small text-vsmall" style={{textAlign: 'center'}}> */}
                        <p className="lab-paragraph u-margin-bottom-small text-vsmall force-center">
                            We are equipped with the latest and best Sorters, barbenders, precision machines, rotap machines, Wiley mills to maintain quality and consistency throughout our process
                            {/* <b><u>Degradation Shaker:</u></b> This is to measure the lamina particle sizes.This will do separation of tobacco particles as per sizes with grater accuracy and balanced vibration.<br/>
                            <b><u>Stem Tester:</u></b>This  is a fast laboratory machine to resolve/  calculate tobacco stem content from threshing line lamina quickly.<br/> 
                            <b><u>Ro-Tap Analysis:</u></b>This is used for doing Objectionable Stem & Fiber Content analysis which is a crucial method used to enhance quality control.  */}
                        </p>
                        {/* <p className="lab-heading u-margin-bottom-small text-small">
                            Chemical
                        </p>
                        <p className="lab-paragraph u-margin-bottom-small text-vsmall" style={{textAlign: 'left'}}>
                            <b><u>Fume Hood:</u></b>Fume Hood is a reliable engineering control for proper laboratory exhaust system for the safety during preparing reagents for chemical analysis. <br/>
                            <b><u>Analytical Auto Analyzer:</u></b>This is a continuous flow analyzer to analyze complex chemistries such as
                            <ul> 
                                <li>Nicotines</li>
                                <li>Total reducing sugars (TRS)</li>
                                <li>Total soluble sugars ( TSS )</li>
                                <li>Chlorides</li>
                            </ul>
                        </p>
                        <p className="lab-heading u-margin-bottom-small text-small">
                            Moisture
                        </p>
                        <p className="lab-paragraph u-margin-bottom-small text-vsmall" style={{textAlign: 'left'}}>
                            <b><u>Willy Mill:</u></b> Enables to prepare tobacco powder sample  for the moisture analysis.<br/> 
                            <b><u>Oven:</u></b>This is to measure accurate moisture levels in tobacco product with precise drying methods.<br/>
                        </p> */}
                    {/* </div> */}
                </div>
                <div className="u-padding u-center col-images">
                    <div class="img-col force-center">
                        <div class="qc-img-composition force-center">
                            <img src={Lab2} alt="Loading..." class="qc-img-1 qc-all-img" /> 
                            <img src={Inside1} alt="Loading..." class="qc-img-2 qc-all-img" />
                            <img src={Inside2} alt="Loading..." class="qc-img-3 qc-all-img" />
                        </div>
                    </div>
                </div>
                 
            </div>
        </div>
    )
}

export default QualityControl
