import React from 'react'

import './Footer.css'

const Footer = () => {
    return (
        <section className="footer">
            <div className="footer_copyright u-margin-top-small">
                <p>
                    &#169; Deccan Tobacco Company.
                </p>
                <p>
                    Built by Shuraiq.
                </p>
                <p className="footer_inspire">
                    Design inspiration: Jonas Schmedtmann.
                </p>
            </div>
            
        </section>
    )
}

export default Footer

