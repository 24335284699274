import React,{useState} from 'react'

import Mininav from '../Mininav/Mininav'
import './Mininav.css'

const MininavHolder = ({handleBackChange,back}) => {
    const [miniopenNav,setMiniOpenNav] = useState(false)

    const handleNav = () => {
        handleBackChange()
        setTimeout(function(){ setMiniOpenNav(!miniopenNav) }, 300);
    }
    return (
        <div>
            <div className={back? "small-nav-background small-nav-background-open":"small-nav-background"}>&nbsp;</div>
            <div className="small-nav-button" onClick={handleNav}>        
                <div className="small-nav-icon-holder" >
                    <span className="small-nav-icon icon-1">&nbsp;</span>
                    <span className="small-nav-icon icon-2">&nbsp;</span>
                    <span className="small-nav-icon icon-3">&nbsp;</span>
                </div>
            </div> 
            {miniopenNav&& <Mininav />} 
        </div>
    )
}

export default MininavHolder
