import React from 'react'
import {NavLink} from 'react-router-dom'

import NavlinksHolder from './NavlinksHolder'
import MininavHolder from '../Mininav/MininavHolder'

import logo from '../../assets/logo.jpg'

import './Navbar.css'

const Navbar = (props) => {
    return (
        <div className="navbar-holder">
            <NavLink to="/" className="logo-box">
                <img src={logo} alt="Logo" className="logo" />
            </NavLink>
            {!props.isMobile && <NavlinksHolder home={false} />}
            
            <MininavHolder />
        </div>
    )
}

export default Navbar
